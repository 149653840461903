import {Button, Cascader, Form, Input, Modal, Select} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import AddNewPictures from '../AddNewPictures';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showNetError, showSuccess} from "../functions";
import PickLocationModal from '../pickLocationModal';
import {addCommunity, addHydrant, getCommunityId, getCommunityList} from "../../api";
import AddNewFile from "../AddNewFile";

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {

};

const AddCommunityModal = ({
                               open,
                               onClose,
                               type,
                               handleGetCommunity
                           }: { open: boolean, onClose: () => void, type: number, handleGetCommunity: any }) => {
    const [form] = Form.useForm();
    const [generatedCommunityId, setGeneratedCommunityId] = useState<string>("");
    const handleGetGeneratedCommunityId = () => {
        getCommunityId().then((res: any) => {
            if (res?.code === 200) {
                setGeneratedCommunityId(res?.msg)
            }
        }).catch((err: any) => {
            console.log(err);
            showNetError();
        })
    }

    const [parentCommunities, setParentCommunities] = useState<any>()

    useEffect(() => {
        if (open) {
            handleGetGeneratedCommunityId()
            getCommunityList({parentId:0}).then((res: any) => {
                if (res?.code === 200) {
                    let parents=[]
                    for (let i = 0; i < res.data.length; i++) {
                        parents.push({label:res.data[i].communityName,value:res.data[i].communityId})
                    }
                    setParentCommunities(parents)
                }
            }).catch((err: any) => {
                console.log(err);
                showNetError();
            })
        }
    }, [open])




    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});


    //新增宿舍中的选择位置对话框
    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }

    const confirmAddress = (e: any) => {
        form.setFieldsValue({"address": e})
    }


    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }


    const [loading, setLoading] = useState<boolean>(false);
    const handleAddNewHydrant = () => {
        form.validateFields().then((value) => {
            console.log(value)
            let newCommunity = {
                communityLng: value?.location?.lng,
                communityLat: value?.location?.lat,
                communityName: value?.communityName,
                haveServiceCompany: value?.haveServiceCompany,
                isCountry: value?.isCountry,
                parentId:value?.parentId,
            }
            setLoading(true);
            addCommunity(newCommunity).then((res: any) => {
                setLoading(false);
                console.log(res)
                if (res?.code === 200) {
                    handleGetCommunity().then(() => {
                        showSuccess(res?.msg);
                        form.resetFields();
                        onClose();
                        setLoc(undefined);
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }


    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();
                setLoc(undefined);
                setLoading(false)
            }} width={'800px'} title={"社区" } maskClosable={false}
                   okText={'确认提交'}
                   onOk={debounce(handleAddNewHydrant, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="communityName"
                        label="社区名称"
                        rules={[
                            {
                                required: true, message: "请输入社区名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入社区名称"/>
                    </Form.Item>

                    <Form.Item
                        name="location"
                        label="定位"
                    >
                        {loc ? (<div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{color: "#235c7a"}}>
                                    <div>经度:{loc?.lng}</div>
                                    <div>纬度:{loc?.lat}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickLocModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                getLocation();
                                setPickLocModalOpen(true);
                            }}>选择定位
                            </Button>
                        )}
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*    name="address"*/}
                    {/*    label="具体地址"*/}
                    {/*>*/}
                    {/*    <Input.TextArea placeholder="请输入具体地址"/>*/}
                    {/*</Form.Item>*/}
                            <Form.Item
                                name="haveServiceCompany"
                                label="有无物业"
                            >
                                <Select options={[{value:1,label:"有"},{value:0,label:"无"}]}>
                                </Select>
                            </Form.Item>
                    <Form.Item
                        name="isCountry"
                        label="是否村舍"
                    >
                        <Select options={[{value:1,label:"是"},{value:0,label:"否"}]}>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="parentId"
                        label="所属社区"
                    >
                        <Select allowClear={true} options={parentCommunities}>
                        </Select>
                    </Form.Item>
                </Form>

                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmAddress={confirmAddress} confirmRoad={() => {
                }} confirmName={() => {
                }}
                                   repick={undefined}/>

            </Modal>


        </div>
    );
};

export default AddCommunityModal;