import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal, Popconfirm, Select, Table, Tooltip, Upload} from "antd";
import {Map, Marker} from "react-amap";
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showNetError, showSuccess} from "../functions";
import {
    addGarbageBox,
    addPoint,
    deleteGarbageBox,
    getBoxRecordPage,
    getBoxRecords,
    getCommunity,
    getGarbageBoxPage, updateGarbageBox
} from "../../api";
import {iCommunity} from "../../interface";
import TextArea from "antd/es/input/TextArea";
import {UploadOutlined} from "@ant-design/icons";
import PickLocationModal from "../pickLocationModal";
import locationIcon from "../../assets/icons/location.svg";
import noneIcon from "../../assets/icons/none.svg";
const AddNewPoiModal = ({open, onCancel, community,handleGetCommunity}:{
    open: boolean,
    onCancel: () => void,
    community:iCommunity,
    handleGetCommunity?: any
}) => {
    const [buildingAreaList, setBuildingAreaList] = useState([]);
    const handleGetBuildingArea = (pageNum:any,pageSize:any) => {
        let params:any = {};
        params.pageNum = pageNum;
        params.pageSize = pageSize;
        params.communityId=community?.communityId

        setLoading(true);
        getGarbageBoxPage(params).then((res:any) => {
            setLoading(false);
            if (res?.code === 200) {
                setBuildingAreaList(res?.data?.records);
                setTotal(parseInt(res?.data?.total));
            } else {
                message.error(res?.msg)
            }
        }).catch((err:any) => {
            console.log(err)
        })
    }


    useEffect(() => {
        if (open) {
            handleGetBuildingArea(1,10)
        }
    }, [open])


    const [addModalOpen, setAddModalOpen] = useState(false);
    const handleCancelAddModal = () => {
        setAddModalOpen(false);
    }
    const [lookModalOpen, setLookModalOpen] = useState(false);
    const handleCancelLookModal = () => {
        setLookModalOpen(false);
    }
    const LookVideoModal = ({open, onCancel}:any) => {
        return (
            <Modal width={'50vw'} maskClosable={false} title={"视频预览"} open={open} onCancel={onCancel}
                   footer={
                       <div>

                       </div>
                   }>

            </Modal>
        )
    }

    const [videoUrl,setVideoUrl]=useState("")

    const AddAssetTypeModal = ({open, onCancel}:any) => {
        const [addLoading, setAddLoading] = useState(false);
        const [form] = Form.useForm();
        const handleAddAssetType = () => {
            form.validateFields().then((value) => {
                setAddLoading(true);
                let newArea = {
                    communityId: community?.communityId,
                    boxName: value?.boxName,
                    longitude:loc?.lng,
                    latitude:loc?.lat,
                }

                addGarbageBox(newArea).then((res:any) => {
                    setAddLoading(false);
                    if (res?.code === 200) {
                        showSuccess(res?.msg, () => {
                            onCancel();
                            handleGetBuildingArea(1,10)
                        })
                    } else {
                        showFail(res?.msg)
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }).catch((err) => {
                console.log(err);
                showNeedCompleteInfo()
            })
        }
        let url="";
        if (process.env.NODE_ENV === 'development') {
            url = "http://localhost:8010/api";
        } else {
            url = "http://60.188.49.10:9090/api";
        }
        const [fileList, setFileList] = useState([]); // 使用useState来管理文件列表

        const handleChange = (info:any) => {
            let fileList:any = [...info.fileList];
            fileList = fileList.slice(-1); // 只保留最后一个上传的文件
            setFileList(fileList);
            console.log(info.file)
            if(info.file.status==='done' && info.file.response.code===200){
                setFileList([]);
                setAddModalOpen(false)
                form.resetFields();
                handleGetBuildingArea(pageNum,pageSize)
            }
        };

        const onRemove = (file:any) => {
            setFileList([]);
        };

        const getExtraData = () => {
            const videoName = form.getFieldValue('videoName')
            const videoDetail = form.getFieldValue('videoDetail')
            // 返回额外的上传数据
            return {
                videoName: videoName,videoDetail:videoDetail,communityId:community?.communityId
            };
        };


        const uploadProps = {
            listType: "picture", // 显示为图片样式
            name: 'file', // 上传文件字段名
            fileList: fileList, // 当前文件列表，需通过useState来管理
            action: `${url}/areaVideo/upload`, // 上传文件的API地址
            onChange: handleChange, // 上传状态改变时的回调
            onRemove: onRemove, // 文件移除时的回调
            maxCount: 1, // 最多上传一个文件
            data: getExtraData, // 额外的上传数据
            headers: {
                Authorization: window.localStorage.getItem('token'), // 设置请求头Authorization字段
            },
            beforeUpload: (file:any) => {
                const videoName = form.getFieldValue('videoName')
                const videoDetail = form.getFieldValue('videoDetail')
                const isLt30M = file.size / 1024 / 1024 < 100;
                const isMp4 = file.type === 'video/mp4';
                const isNotNull=videoName&&videoDetail
                if (!isMp4) {
                    message.error(`${file.name} 不是 MP4 文件`);
                }
                if (!isLt30M) {
                    message.error(`${file.name} 文件大小超过100MB`);
                }
                if (!isNotNull) {
                    message.error(`名称和描述不能为空`);

                }
                return (isMp4 && isLt30M && isNotNull)|| Upload.LIST_IGNORE;
            },
            afterUpload: (res:any) => {
                console.log(res)
                setAddModalOpen(false)
                setFileList([])
                handleGetBuildingArea(pageNum,pageSize)
            },
        };

        const [loc, setLoc] = useState<any>()
        const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});


        //新增宿舍中的选择位置对话框
        const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
        const handleCancelLocModal = () => {
            setPickLocModalOpen(false);
        }


        const confirmLoc = (e: any) => {
            setLoc(e);
            form.setFieldsValue({"location": e})
        }

        const confirmAddress = (e: any) => {
            form.setFieldsValue({"address": e})
        }


        const getLocation = () => {
            console.log(navigator.geolocation)
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((l) => {
                    if (l?.coords) {
                        setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                    } else {
                        showFail('获取定位失败')
                    }
                });
            } else {
                alert("浏览器不支持地理定位。");
            }
        }
        return (
            <Modal width={'50vw'} maskClosable={false} title={"新增箱体"} open={open} onCancel={onCancel}
                   footer={
                       <div><Button onClick={handleAddAssetType}>提交</Button></div>
                   }>
                <Form form={form} name="userForm" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 4},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 18},
                }}>
                    <Form.Item
                        name="boxName"
                        label="名称"
                        rules={[
                            {required: true, message: '请输入名称'}
                        ]}
                    >
                        <Input placeholder={'请填写箱体名称'}/>
                    </Form.Item>
                    <Form.Item
                        name="location"
                        label="定位"
                    >
                        {loc ? (<div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{color: "#235c7a"}}>
                                    <div>经度:{loc?.lng}</div>
                                    <div>纬度:{loc?.lat}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickLocModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                getLocation();
                                setPickLocModalOpen(true);
                            }}>选择定位
                            </Button>
                        )}
                    </Form.Item>

                </Form>
                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmAddress={confirmAddress} confirmRoad={() => {
                }} confirmName={() => {
                }}
                                   repick={undefined}/>
            </Modal>
        )
    }
    const [updateBoxModel, setUpdateBoxModel] = useState(false);
    const handleUpdateBoxModel = () => {
        setUpdateBoxModel(false);
    }

    const UpdateBoxModal = ({open, onCancel}:any) => {
        const [addLoading, setAddLoading] = useState(false);
        const [form] = Form.useForm();
        const handleUpdateAssetType = () => {
            form.validateFields().then((value) => {
                setAddLoading(true);
                let newBox = {
                    communityId: community?.communityId,
                    boxName: value?.boxName,
                    longitude:loc?.lng,
                    latitude:loc?.lat,
                    boxCode:box?.boxCode,
                }

                updateGarbageBox(newBox).then((res:any) => {
                    setAddLoading(false);
                    if (res?.code === 200) {
                        showSuccess(res?.msg, () => {
                            onCancel();
                            handleGetBuildingArea(1,10)
                        })
                    } else {
                        showFail(res?.msg)
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }).catch((err) => {
                console.log(err);
                showNeedCompleteInfo()
            })
        }


        const [loc, setLoc] = useState<any>()
        const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});

        useEffect(() => {
            if (open && box) {
                setLoc({lng: box?.longitude, lat: box?.latitude});
                setInitLoc({lng: Number(box?.longitude), lat: Number(box?.latitude)});
                form.setFieldValue("boxName",box?.boxName)
                form.setFieldValue("location",{lng:box?.longitude,lat:box?.latitude})

            }
        }, [open, box]);

        //新增宿舍中的选择位置对话框
        const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
        const handleCancelLocModal = () => {
            setPickLocModalOpen(false);
        }


        const confirmLoc = (e: any) => {
            setLoc(e);
            form.setFieldsValue({"location": e})
        }

        const confirmAddress = (e: any) => {
            form.setFieldsValue({"address": e})
        }


        const getLocation = () => {
            console.log(navigator.geolocation)
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((l) => {
                    if (l?.coords) {
                        setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                    } else {
                        showFail('获取定位失败')
                    }
                });
            } else {
                alert("浏览器不支持地理定位。");
            }
        }
        return (
            <Modal width={'50vw'} maskClosable={false} title={"修改箱体"} open={open} onCancel={onCancel}
                   footer={
                       <div><Button onClick={handleUpdateAssetType}>提交</Button></div>
                   }>
                <Form form={form} name="userForm" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 4},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 18},
                }}>
                    <Form.Item
                        name="boxName"
                        label="名称"
                        rules={[
                            {required: true, message: '请输入名称'}
                        ]}
                    >
                        <Input placeholder={'请填写箱体名称'}/>
                    </Form.Item>
                    <Form.Item
                        name="location"
                        label="定位"
                        initialValue={loc}
                        rules={[
                            {
                                required: true, message: "请选择定位"
                            },
                        ]}
                    >
                        {loc ? (<div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{color: "#235c7a"}}>
                                    <div>经度:{loc?.lng}</div>
                                    <div>纬度:{loc?.lat}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickLocModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                getLocation();
                                setPickLocModalOpen(true);
                            }}>选择定位
                            </Button>
                        )}
                    </Form.Item>

                </Form>
                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmAddress={confirmAddress} confirmRoad={() => {
                }} confirmName={() => {
                }}
                                   repick={undefined}/>
            </Modal>
        )
    }
    //位置对话框
    const [boxCode, setBoxCode] = useState<string>("");
    const [boxMapModalOpen, setBoxMapModalOpen] = useState(false);
    const handleCloseMapModal = () => {
        setBoxMapModalOpen(false);
    }
    const [box, setBox] = useState<any>();
    const columns:any = [

        {
            title: '箱体名称',
            dataIndex: 'boxName',
            key: 'boxName',
            visible: true,
        },
        {
            title: '地图及点位',
            dataIndex: 'location',
            key: 'location',
            render: (_: any, record: any) => (
                <>
                    {(record?.longitude && record?.latitude) ? (
                        <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                             onClick={() => {
                                 setBoxMapModalOpen(true);
                                 setBoxCode(record?.boxCode)
                                 setBox(record)
                             }}/>
                    ) : (<div>
                        <Tooltip title={'该社区暂无定位'}>
                            <img src={noneIcon} alt="" width={20} height={20}/>
                        </Tooltip>
                    </div>)}
                </>
            ),
            fixed: 'right',
        },
        {
            title: '操作',
            key: 'action',
            render: (_:any, record:any) => (
                <>
                    <Button  type='link' onClick={()=>{
                        setUpdateBoxModel(true)
                        setBox(record)
                    }}>修改</Button>
                    <Popconfirm
                        placement="topLeft"
                        title='是否删除该箱体'
                        onConfirm={() => {
                            console.log(record)
                            deleteGarbageBox({boxCode:record?.boxCode}).then((res:any) => {
                                if (res?.code === 200) {
                                    message.success(res?.msg, 1.2).then(() => {
                                        handleGetBuildingArea(pageNum,pageSize);
                                    });
                                } else {
                                    message.error(res?.msg);
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}
                        okText="确认删除"
                        cancelText="取消"
                    >
                        <Button danger type='link'>删除</Button>
                    </Popconfirm>

                </>
            ),
        },

    ];
    const [loading, setLoading] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const onPageChange = (_page:any) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page:any, _pageSize:any) => {
        setPageSize(_pageSize);
    };
    const [total, setTotal] = useState(0);
    let params = {};
    return (
        <Modal width={'50vw'} cancelText="取消" cancelButtonProps={{style: {display: 'none'}}} title="箱体" open={open}
               onOk={onCancel} onCancel={onCancel}>
            <div>
                <div style={{float:'right',marginBottom:'10px'}}>
                    <Button type={'primary'} onClick={()=>{setAddModalOpen(true)}}>新增</Button>
                </div>
                <Table
                    columns={columns}
                    dataSource={buildingAreaList} loading={loading} pagination={{
                    current: pageNum,
                    onChange: onPageChange,
                    onShowSizeChange: onPageSizeChange,
                    total: total,
                    showSizeChanger: true,
                    pageSize: pageSize,
                    pageSizeOptions: ['10', '20', '50', '100'],
                }}/>
            </div>
            <AddAssetTypeModal open={addModalOpen} onCancel={handleCancelAddModal}/>
            <UpdateBoxModal open={updateBoxModel} onCancel={handleUpdateBoxModel}/>
            <LookVideoModal open={lookModalOpen} onCancel={handleCancelLookModal}/>
            <Modal open={boxMapModalOpen} onCancel={handleCloseMapModal} title={`${box?.boxName}`} width={'800px'}
                   cancelButtonProps={{style: {display: 'none'}}} onOk={handleCloseMapModal}>

                <div style={{width: '100%', height: "40vh"}}>
                    <Map plugins={['ToolBar']}
                         center={{
                             longitude: Number(box?.longitude),
                             latitude: Number(box?.latitude)
                         }}
                         zoom={17}>
                        <Marker position={{longitude: box?.longitude as unknown as number, latitude: box?.latitude as unknown as number}}/>

                    </Map>


                </div>


            </Modal>
        </Modal>
    )
}
export default AddNewPoiModal;