import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image
} from 'antd';
import React, {useEffect, useState} from 'react';
import styles from "./index.module.css"
import {iCommunity, iHydrant} from "../../../interface";
import {
    deleteCommunity,
    getCommunityPage
} from "../../../api";
import {getAreaPath, showFail, showSuccess, transform} from "../../../components/functions";
import locationIcon from "../../../assets/icons/location.svg"
import noneIcon from "../../../assets/icons/none.svg"

// @ts-ignore
import {debounce} from "lodash";
import CommunityContactsModal from "../../../components/communityContactsModal";
import AddCommunityModal from "../../../components/addCommunityModal";
import EditCommunityModal from "../../../components/editCommunityModal";
import AddNewContactModal from "../../../components/addNewContactModal";
import AddNewPoiModal from "../../../components/addNewPoiModal";
import CommunityMapModal from "../../../components/communityMapModal";
import PickLocationModal from "../../../components/pickLocationModal";
import {Map, Marker} from "react-amap";

const CommunityComponent = ({type}: { type: any }) => {
        const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);

        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)


        const [communityList, setCommunityList] = useState<iCommunity[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        let params: any = {}
        const handleGetCommunityList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            if (searchCondition?.communityName) {
                params.communityName = searchCondition?.communityName
            }
            if (searchCondition?.communityAddress) {
                params.communityAddress = searchCondition?.communityAddress
            }
            if (searchCondition?.areaId) {
                params.areaId = searchCondition?.areaId
            }
            params.type = type
            getCommunityPage(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setCommunityList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetCommunityList(pageNum, pageSize, newSearchCondition);
        }, [pageSize, pageNum])



        const [searchCondition, setSearchCondition] = useState<any>({
            communityId:""
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

        const handleNameChange = (e: any) => {
            setSearchCondition({...searchCondition, hydrantName: e?.target?.value})
        }

        const handleAddressChange = (e: any) => {
            setSearchCondition({...searchCondition, hydrantAddress: e?.target?.value})
        }

        const handleAreaChange = (v: any) => {
            if (v) {
                setSearchCondition({...searchCondition, areaId: v[v?.length - 1]})
            } else {
                setSearchCondition({...searchCondition, areaId: ""})
            }
        }

        const [imageVisible, setImageVisible] = useState<boolean>(false);
        const [communityPictures, setCommunityPictures] = useState<any[]>([]);


        //位置对话框
        const [communityId, setCommunityId] = useState<string>("");
        const [communityMapModalOpen, setCommunityMapModalOpen] = useState(false);
        const [community, setCommunity] = useState<any>();

    const handleCloseMapModal = () => {
            setCommunityMapModalOpen(false);
        }

        //联系人对话框
        const [communityContactsModalOpen, setCommunityContactsModalOpen] = useState(false);
        const handleCloseCommunityContactsModal = () => {
            setCommunityContactsModalOpen(false);
        }

        const columns: any = [
            {
                title: '社区名称',
                dataIndex: 'communityName',
                key: 'communityName',
            },
            {
                title: '有无物业',
                dataIndex: 'haveServiceCompany',
                render: (_: any, record: any) => (
                    <>
                        {(record?.haveServiceCompany==1) ? (
                           '有'
                        ) : ('无')}
                    </>
                ),
            },
            {
                title: '是否村舍',
                dataIndex: 'isCountry',
                render: (_: any, record: any) => (
                    <>
                        {record?.isCountry==1 ?'是':'否'}
                    </>
                ),
            },
            {
                title: '地图及点位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.communityLat && record?.communityLng) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setCommunityMapModalOpen(true);
                                     setCommunityId(record?.communityId)
                                     setCommunity(record)
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'该社区暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                ),
            },
            {
                title: '所属社区',
                dataIndex: ['communityParent','communityName'],
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                fixed: 'right',
                render: (_: any, record: any) => (
                    <div>

                         <Button type={'link'} onClick={() => {
                                setCommunityRecord(record);
                                setAddPoiModalOpen(true);
                         }}>箱体管理</Button>

                        <Button type={'link'} onClick={() => {
                                setCommunityRecord(record);
                                setEditModalVisible(true);
                        }}>修改</Button>

                        {isSuperManager && (
                            <Popconfirm title={"是否确认删除该社区"} onConfirm={() => {
                                deleteCommunity({communityId: record?.communityId}).then((res: any) => {
                                    if (res?.code === 200) {
                                        handleGetCommunityList(pageNum, pageSize, newSearchCondition).then(() => {
                                            showSuccess(res?.msg);
                                        })
                                    } else {
                                        showFail(res?.msg)
                                    }
                                }).catch((err) => {
                                    console.log(err)
                                })
                            }}>
                                <Button danger type={'link'} color={'danger'}>删除</Button>
                            </Popconfirm>
                        )}


                    </div>
                )
            },
        ];

        //新增
        const [addType, setAddType] = useState<number>(1);
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }

        //修改
        const [communityRecord, setCommunityRecord] = useState<iCommunity | undefined>();
        const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
        const handleCloseEditModal = () => {
            setEditModalVisible(false);
        }


        //新增联系人
        const [addContactModalOpen, setAddContactModalOpen] = useState<boolean>(false);
        const handelCloseAddContactModal = () => {
            setAddContactModalOpen(false);
        }

        //新增点位
        const [addPoiModalOpen, setAddPoiModalOpen] = useState<boolean>(false);
        const handelCloseAddPoiModal = () => {
            setAddPoiModalOpen(false);
        }

        return (
            <div className={styles.main}>
                <div className={styles.mainHead}>
                    <div className={styles.searchContent}>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleNameChange} placeholder={'社区名称'} allowClear/>


                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleAddressChange} placeholder={'社区地址'} allowClear/>

                    </div>
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetCommunityList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                            // setAddModalVisible(true);

                            setAddType(type);
                            setAddModalVisible(true)

                        }}>新增
                        </Button>

                    </div>

                </div>

                <Table rowKey={'communityId'}  columns={columns} dataSource={communityList}
                       loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>
                <Image.PreviewGroup
                    items={communityPictures}
                    preview={{
                        visible: imageVisible,
                        onVisibleChange: (value) => {
                            setImageVisible(value);
                        },
                    }}
                >
                    {communityPictures.map((image: any) => (
                        <Image
                            key={image.key}
                            width={200}
                            height={200}
                            style={{display: 'none'}}
                            src={image.src}
                            preview={{
                                scaleStep: 0.5,
                                src: image.src,
                            }}
                        />
                    ))}
                </Image.PreviewGroup>


                <CommunityContactsModal open={communityContactsModalOpen} onCancel={handleCloseCommunityContactsModal}
                                        communityId={communityId}/>

                <AddCommunityModal open={addModalVisible} onClose={handleCloseAddModal}
                                   type={addType}
                                   handleGetCommunity={async () => {
                                       await handleGetCommunityList(pageNum, pageSize, newSearchCondition)
                                   }}/>

                <EditCommunityModal open={editModalVisible} onClose={handleCloseEditModal} areaOptions={[]}
                                    handleGetCommunity={async () => {
                                        await handleGetCommunityList(pageNum, pageSize, newSearchCondition)
                                    }} community={communityRecord as iCommunity}/>

                <AddNewContactModal open={addContactModalOpen} onCancel={handelCloseAddContactModal}
                                    community={communityRecord as iCommunity} handleGetCommunity={async () => {
                }}/>


                <AddNewPoiModal open={addPoiModalOpen} onCancel={handelCloseAddPoiModal}
                                handleGetCommunity={async () => {
                                    await handleGetCommunityList(pageNum, pageSize, newSearchCondition)
                                }} community={communityRecord as iCommunity}
                />
                {/*<CommunityMapModal open={communityMapModalOpen} onCancel={handleCloseMapModal} />*/}
                <Modal open={communityMapModalOpen} onCancel={handleCloseMapModal} title={`${community?.communityName}`} width={'800px'}
                       cancelButtonProps={{style: {display: 'none'}}} onOk={handleCloseMapModal}>

                    <div style={{width: '100%', height: "40vh"}}>
                        <Map plugins={['ToolBar']}
                             center={{
                                 longitude: Number(community?.communityLng),
                                 latitude: Number(community?.communityLat)
                             }}
                             zoom={17}>
                            <Marker position={{longitude: community?.communityLng, latitude: community?.communityLat}}/>

                        </Map>


                    </div>


                </Modal>
            </div>
        );
    }
;

export default CommunityComponent;