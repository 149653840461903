import {Button, Cascader, Form, Input, Modal, Select} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import AddNewPictures from '../AddNewPictures';
// @ts-ignore
import {debounce} from "lodash"
import {getAreaIdArray, showFail, showNeedCompleteInfo, showNetError, showSuccess, showWarning} from "../functions";
import PickLocationModal from '../pickLocationModal';
import {addCommunity, addHydrant, getCommunityId, getCommunityList, updateCommunity} from "../../api";
import {iCommunity} from "../../interface";
import UpdatePictures from '../modifyPictures';
import AddNewFile from "../AddNewFile";
import UpdateFile from "../UpdateFile";

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;

    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();

        }
    }, [form, prevOpen, open]);
};

const EditCommunityModal = ({
                                open,
                                onClose,
                                areaOptions,
                                community,
                                handleGetCommunity
                            }: {
    open: boolean, onClose: () => void, areaOptions: any,
    community: any,
    handleGetCommunity: any
}) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });


    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});


    //新增宿舍中的选择位置对话框
    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }

    const confirmAddress = (e: any) => {
        form.setFieldsValue({"address": e})
    }


    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }

    const [areaIdArrays, setAreaIdArrays] = useState<any[]>([])
    useEffect(() => {
        if (open) {
            form.resetFields();
            setLoc({lng: community?.communityLng, lat: community?.communityLat});
            setInitLoc({lng: Number(community?.communityLng), lat: Number(community?.communityLat)});
            getCommunityList({parentId:0}).then((res: any) => {
                if (res?.code === 200) {
                    let parents=[]
                    for (let i = 0; i < res.data.length; i++) {
                        parents.push({label:res.data[i].communityName,value:res.data[i].communityId})
                    }
                    setParentCommunities(parents)
                }
            }).catch((err: any) => {
                console.log(err);
                showNetError();
            })
        }
    }, [open])


    const [loading, setLoading] = useState<boolean>(false);
    const handleAddNewHydrant = () => {
        form.validateFields().then((value) => {
            console.log('value',value);

            let updatedCommunity = {
                communityLng: value?.location?.lng,
                communityLat: value?.location?.lat,
                communityId: community?.communityId,
                haveServiceCompany: value?.haveServiceCompany,
                isCountry: value?.isCountry,
                parentId:value?.parentId,
                communityName:value?.communityName,
            }
            setLoading(true);
            updateCommunity(updatedCommunity).then((res: any) => {
                setLoading(false);
                console.log(res)
                if (res?.code === 200) {
                    handleGetCommunity().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                        setLoc(undefined);
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }
    const [parentCommunities, setParentCommunities] = useState<any>()

    const [pdfUrl,setPdfUrl]=useState<string>("");
    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();
                setLoc(undefined);
                setLoading(false)
            }} width={'800px'} title={`${community?.type===1?'社区':community?.type===2?'企业':"特殊场所"}修改`} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleAddNewHydrant, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>

                    <Form.Item
                        name="location"
                        label="定位"
                        initialValue={loc}
                        rules={[
                            {
                                required: true, message: "请选择定位"
                            },
                        ]}
                    >
                        {loc ? (<div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{color: "#235c7a"}}>
                                    <div>经度:{loc?.lng}</div>
                                    <div>纬度:{loc?.lat}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickLocModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                getLocation();
                                setPickLocModalOpen(true);
                            }}>选择定位
                            </Button>
                        )}
                    </Form.Item>



                    <Form.Item
                        name="communityName"
                        label="名称"
                        initialValue={community?.communityName}
                        rules={[
                            {
                                required: true, message: "请输入名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入名称"/>
                    </Form.Item>
                    <Form.Item
                        name="haveServiceCompany"
                        label="有无物业"
                        initialValue={community?.haveServiceCompany}
                    >
                        <Select options={[{value:1,label:"有"},{value:0,label:"无"}]}>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="isCountry"
                        label="是否村舍"
                        initialValue={community?.isCountry}
                    >
                        <Select options={[{value:1,label:"是"},{value:0,label:"否"}]}>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="parentId"
                        label="所属社区"
                        initialValue={community?.parentId}
                    >
                        <Select allowClear={true} options={parentCommunities}>
                        </Select>
                    </Form.Item>
                </Form>


                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmAddress={confirmAddress} confirmRoad={() => {
                }} confirmName={() => {}} repick={undefined}/>

            </Modal>


        </div>
    );
};

export default EditCommunityModal;