import {MenuProps, Tooltip} from 'antd';
import {Breadcrumb, Layout, Menu} from 'antd';
import React, {useState} from 'react';
import './index.css';
import {Outlet, useNavigate, useLocation} from "react-router-dom";
import NotificationDropDown from "../../components/notificationDropDown";
import AvatarDropDown from "../../components/avatarDropDown";
import mapIcon from "../../assets/icons/map.svg"
import { showFail } from '../../components/functions';


const App: React.FC = () => {

    const {Header, Content, Footer, Sider} = Layout;

    type MenuItem = Required<MenuProps>['items'][number];

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
        } as MenuItem;
    }

    const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
    const isManager = JSON.parse(window.localStorage?.getItem("isManager") as string);
    let obj: any;
    const items: MenuItem[] = []
    let authentications=window.localStorage.getItem('authentications');
    if(!authentications){
        showFail("登录已失效, 请重新登录", () => {
            window.location.href = "/#/login"
            window.location?.reload();
        })
    }else{
         obj = JSON.parse(window.localStorage.getItem('authentications')!.toString()).map((e: any) => {
            return (e.authority)
        })
        if (obj.indexOf("管理") != -1) {
            let a: any = []
            if (obj.indexOf("人员管理") != -1) {
                a.push(getItem('人员管理', '/manage/user'))
            }
            if (obj.indexOf("角色管理") != -1) {
                a.push(getItem('角色管理', '/manage/role'))
            }
            if (obj.indexOf("权限管理") != -1) {
                a.push(getItem('权限管理', '/manage/menu'))
            }
            if (obj.indexOf("清运公司管理") != -1) {
                a.push(getItem('清运公司管理', '/manage/company'))
            }
            if (obj.indexOf("清运汽车管理") != -1) {
                a.push(getItem('清运汽车管理', '/manage/car'))
            }
            if (obj.indexOf("清运需求管理") != -1) {
                a.push(getItem('清运需求管理', '/manage/demand'))
            }
            if (obj.indexOf("清运作业管理") != -1) {
                a.push(getItem('清运作业管理', '/manage/work'))
            }
            if (obj.indexOf("处置公司管理") != -1) {
                a.push(getItem('处置公司管理', '/manage/sites'))
            }
            if (obj.indexOf("箱体管理") != -1) {
                a.push(getItem('箱体管理', '/manage/garbageBox'))
            }
            if (obj.indexOf("站点管理") != -1) {
                a.push(getItem('站点管理', '/manage/sites'))
            }
            if (obj.indexOf("部门管理") != -1) {
                a.push(getItem('部门管理', '/manage/dept'))
            }
            if (obj.indexOf("区域管理") != -1) {
                a.push(getItem('区域管理', '/manage/area'))
            }
            a.push(getItem('社区管理', '/manage/community'))
            // if (obj.indexOf("在建项目审核") != -1) {
            //     a.push(getItem('在建项目审核', '/manage/projectAudit'))
            // }
            // if (obj.indexOf("道路管理") != -1) {
            //     a.push(getItem('道路管理', '/manage/road'))
            // }
            // if (obj.indexOf("XHS管理") != -1) {
            //     a.push(getItem('XHS管理', '/manage/hydrant'))
            // }
            // a.push(getItem('配置管理', '/manage/dict'))
            //
            // if (obj.indexOf("社区管理") != -1) {
            //     // a.push(getItem('社区&企业管理', '/manage/communityComponent'))
            //     let a1: any = []
            //     a1.push(getItem('社区', '/manage/community'))
            //     a1.push(getItem('特殊场所', '/manage/special_place'))
            //     a1.push(getItem('危化品', '/manage/chemicals'))
            //     a.push(getItem('作战卡', '/manage/communityComponent',undefined, a1))
            // }
            // a.push( getItem('配件管理', '/xc/manage/accessory_manage'))

            items.push(getItem('管理', 'manage',undefined, a))
        }

        // if (obj.indexOf("巡检") != -1) {
        //     let b: any = []
        //     if (obj.indexOf("巡检任务") != -1) {
        //         b.push(getItem('巡检任务', '/manage/inspect_task'))
        //     }
        //     if (obj.indexOf("定期计划") != -1) {
        //         b.push(getItem('定期计划', '/manage/inspect_task/regular'))
        //     }
        //     items.push(getItem('巡检', 'inspect_task',undefined, b))
        // }
        //
        // if (obj.indexOf("防消联勤") != -1) {
        //     let c: any = []
        //     if (obj.indexOf("防消联勤") != -1) {
        //         c.push(getItem('隐患排查', '/manage/hiddenDanger'))
        //     }
        //     if (obj.indexOf("防消联勤") != -1) {
        //         c.push(getItem('演练学习', '/manage/exercise'))
        //     }
        //     items.push(getItem('防消联勤', '/exercise',undefined, c))
        // }
    }


    const location = useLocation();
    const {pathname} = location;
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const onClick: MenuProps['onClick'] = e => {
        navigate(e.key, {replace: false})
    };
    const defaultOpenKeys = ['manage'];
    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
                <div className="logo"/>
                <Menu theme="dark" selectedKeys={[pathname]} mode="inline" defaultOpenKeys={defaultOpenKeys} items={items} onClick={onClick}/>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-header" style={{padding: 0}}>

                    <div className="notification">

                    <Tooltip title='查看地图'>
                            <img src={mapIcon} alt="" width={25} height={25} onClick={() => {
                                navigate("/map")
                            }}/>
                        </Tooltip>
                    </div>

                    {/*<div className="notification">*/}
                    {/*    <NotificationDropDown/>*/}
                    {/*</div>*/}
                    <div className="Avatar">
                        <AvatarDropDown/>
                    </div>
                </Header>
                <Content style={{margin: '0 16px'}}>
                    <Breadcrumb style={{margin: '16px 0'}}/>
                    <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                        <Outlet/>
                    </div>
                </Content>
                <Footer style={{textAlign: 'center'}}>
                    <a style={{marginLeft: '20px', color: '#287ec0', cursor: 'pointer'}} href={'https://beian.miit.gov.cn/'}>南浔智慧清运系统 ©2024 浙ICP备20018658号-2</a>
                </Footer>
            </Layout>
        </Layout>
    );
};

export default App;