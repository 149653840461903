import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image, Divider, Space, Radio, InputNumber
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    addWork,
    deleteWork, getAllCarNotPage, getAllCompanyNotPage, getAllWork, updateWork,
} from "../../../api";
import {
    getAreaPath,
    showFail,
    showNeedCompleteInfo,
    showSuccess,
    showWarning,
    transform
} from "../../../components/functions";
import AddRoadModal from "../../../components/addRoadModal";
// @ts-ignore
import {debounce} from "lodash";
import locationIcon from "../../../assets/icons/location.svg";
import noneIcon from "../../../assets/icons/none.svg";
import LocationModal from "../../../components/locationModal";
import {log} from "node:util";
import {Map, Marker} from "react-amap";
// @ts-ignore

const Work = () => {
        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)
        const [locationModalOpen, setLocationModalOpen] = useState(false);

        const [hydrantList, setHydrantList] = useState<iHydrant[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };
        const [hydrantLng, setHydrantLng] = useState<string | number>();
        const [hydrantLat, setHydrantLat] = useState();
        let params: any = {}
        const handleGetCleanWorkList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            console.log( window.localStorage.getItem('companyId') )
            if ( window.localStorage.getItem('companyId') !== 'undefined') {
                params.companyId = window.localStorage.getItem('companyId')
            }
            if(searchCondition?.workerPhone!=null){
                params.workerPhone=searchCondition.workerPhone
            }
            if(searchCondition?.workState!=null){
                params.workState=searchCondition.workState
            }
            if(searchCondition?.companyId!=null){
                params.companyId=searchCondition.companyId
            }
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            getAllWork(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setHydrantList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    const [cars, setCars] = useState<any[]>([]);

        useEffect(() => {
            handleGetCleanWorkList(pageNum, pageSize,searchCondition);
            if(isDispatcher){
                getAllCarNotPage({companyId:window.localStorage.getItem('companyId')}).then((res: any) => {
                    if (res?.code === 200) {
                        let cars = [];
                        for (let i = 0; i < res?.data?.length; i++) {
                            cars.push({value: res?.data[i]?.carId, label: res?.data[i]?.carLicense})
                        }
                        setCars(cars);
                    } else {
                        showFail(res?.msg)
                    }
                })
            }
        }, [pageSize, pageNum])

         const [form] = Form.useForm();

    const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
    const isManager = JSON.parse(window.localStorage?.getItem("isManager") as string);
    const isDispatcher = JSON.parse(window.localStorage?.getItem("isDispatcher") as string);

    const [searchCondition, setSearchCondition] = useState({
            companyId: window.localStorage.getItem('companyId') !== 'undefined' ? window.localStorage.getItem('companyId') : null,
            workerPhone: "",
            workState: isManager?"结束作业":isDispatcher?'待指派':'',
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);
        const [item, setItem] = useState<any>();
         const [workId, setWorkId] = useState<any>();
        const handleCloseLocationModal = () => {
            setLocationModalOpen(false);
        }
        const columns: any = [
            {
                title: '作业单位',
                dataIndex: ['cleanCompany','companyName'],
            },
            {
                title: '需求类型',
                dataIndex: ['cleanDemand','demandType'],
            },
            {
                title: '垃圾重量',
                dataIndex: 'garbageWeight',
                key: 'garbageWeight',
            },
            {
                title: '垃圾类型',
                dataIndex: 'garbageType',
                key: 'garbageType',
            },
            {
                title: '作业状态',
                dataIndex: 'workState',
                key: 'workState',
            },
            {
                title: '作业车辆',
                dataIndex: ['cleanCar','carLicense'],
            },
            {
                title: '作业人员',
                dataIndex: 'workerPhone',
            },  {
                title: '处置站点',
                dataIndex: ['garbageSite','siteName'],
            },
            {
                title: '作业开始时间',
                dataIndex: 'workStartTime',
                key: 'workStartTime',
            },
            {
                title: '作业结束时间',
                dataIndex: 'workEndTime',
                key: 'workEndTime',
            },
            {
                title: '是否退回过',
                dataIndex: 'hasRectify',
                key: 'hasRectify',
                render: (_: any, record: any) => (
                    <>
                        {(record?.hasRectify==1) ? (
                            <div>是</div>
                        ) : (<div>
                          否
                        </div>)}
                    </>
                )
            },
            {
                title: '开始作业定位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.startLat && record?.startLng) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setLocationModalOpen(true);
                                     setHydrantLat(record?.startLat);
                                     setHydrantLng(record?.startLng);
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                )
            },
            {
                title: '结束作业定位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.endLng && record?.endLat) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setLocationModalOpen(true);
                                     setHydrantLat(record?.endLng);
                                     setHydrantLng(record?.endLat);
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                )
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (_: any, record: any) => (
                    <div>

                        {record.workState==='待指派' ? <Button  type={'link'} color={'primary'}
                        onClick={()=>{
                            setUpdateModalVisible(true);
                            setWorkId(record?.workId)
                            setItem(record)
                        }}
                        >指派</Button>:''}
                    </div>
                )
            },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }
    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
    const handleCloseUpdateModal = () => {
        setUpdateModalVisible(false);
    }
    const [result,setResult]=useState()
    const handleUpdateNewWork = () => {
        form.validateFields().then((value) => {
            let newWork = {
                // isPass:value?.isPass,
                // resultReason:value?.resultReason,
                // result:value?.isPass=="1"?"确认通过":"退回",
                carId:value?.carId,
                roadPlan:value?.roadPlan,
                workerPhone:value?.workerPhone,
                transportationCost:value?.transportationCost,
                movingCost:value?.movingCost,
                workId:workId,
            }
            setLoading(true);
            updateWork(newWork).then((res: any) => {
                setLoading(false);
                console.log(res)
                if (res?.code === 200) {
                    form.resetFields()
                    handleGetCleanWorkList(pageNum, pageSize).then(() => {
                        showSuccess(res?.msg);
                        handleCloseUpdateModal();
                        form.resetFields()
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    const [companyList, setCompanyList] = useState<any[]>([]);

    const changeName = (e: any) => {
        let v = e?.target?.value;
        if (v) {
            let params: any = {companyName: v};

            getAllCompanyNotPage(params).then((res: any) => {
                console.log(res)
                if (res?.code === 200) {
                    setCompanyList(res?.data?.map((d: any) => {
                        return {
                            value: d?.companyId,
                            label: d.companyName
                        }
                    }))
                }
            })
        } else {
            showWarning("请输入归属人姓名或手机号")
        }
    }



        return (
            <div className={styles.main}>

                <div className={styles.mainHead}>
                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}} onChange={(e:any)=>{setSearchCondition({workerPhone: e.target.value,companyId: searchCondition.companyId,workState: searchCondition.workState})}} placeholder={'作业人手机号'} allowClear></Input>
                    { window.localStorage.getItem('companyId') == 'undefined'?
                    <Select
                        style={{width: '280px', marginRight: '30px', marginTop: '10px'}}
                        allowClear={true}
                        placeholder={"请选择归属单位"}
                        onChange={(e)=>{
                            console.log(e)
                            setSearchCondition({workerPhone: searchCondition.workerPhone,companyId: e,workState:searchCondition.workState})}
                        }
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider style={{margin: '8px 0'}}/>
                                <Space style={{padding: '4px 8px 4px '}}>
                                    <Input
                                        allowClear
                                        placeholder="请输入单位名称"
                                        onChange={debounce(changeName, 600)}
                                    />
                                </Space>
                            </>
                        )}
                        options={companyList}
                    />:''}
                    <Select
                        style={{width: '280px', marginRight: '30px', marginTop: '10px'}}
                        allowClear={true}
                        placeholder={"请选择作业状态"}
                        onChange={(e)=>{
                            console.log(e)
                            setSearchCondition({workerPhone: searchCondition.workerPhone,companyId: searchCondition.companyId,workState: e})}
                        }
                        defaultValue={ isManager?"结束作业":isDispatcher?'待指派':null}
                        options={[
                            {value: "待指派", label: "待指派"},
                            {value: "已指派", label: "已指派"},
                            {value: "开始作业", label: "开始作业"},
                            {value: "结束作业", label: "结束作业"},
                            {value: "确认完成", label: "确认完成"},
                            {value: "退回", label: "退回"},
                        ]}
                    />
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetCleanWorkList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        {/*<Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {*/}
                        {/*    setAddModalVisible(true);*/}
                        {/*}}>新增*/}
                        {/*</Button>*/}

                    </div>

                </div>

                <Table rowKey={'areaId'} columns={columns} dataSource={hydrantList} loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>


                {/*<AddRoadModal open={addModalVisible} onClose={handleCloseAddModal} areaOptions={[]}*/}
                {/*                 handleGetHydrant={async () => {*/}
                {/*                     await handleGetCleanWorkList(pageNum, pageSize, newSearchCondition)*/}
                {/*                 }}/>*/}
                <Modal
                    style={{zIndex: 0}}
                    open={updateModalVisible} onCancel={() => {
                            handleCloseUpdateModal();
                            setLoading(false)
                        }} title={'作业审核'} maskClosable={false} okText={'确认提交'}
                               onOk={debounce(handleUpdateNewWork, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                    <Form form={form}>
                        <Form.Item style={{ marginBottom: '5px' }} name='companyName' label='清运公司'>
                            <div >{item?.cleanCompany?.companyName}</div>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '5px' }} name='demandType' label='需求类型'>
                            <div>{item?.cleanDemand?.demandType}</div>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '5px' }} name='garbageWeight' label='垃圾重量'>
                            <div>{item?.garbageWeight}</div>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '5px' }} name='garbageType' label='垃圾类型'>
                            <div>{item?.garbageType}</div>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '5px' }} name='workState' label='作业状态'>
                            <div>{item?.workState}</div>
                        </Form.Item>
                        {/*{item?.cleanDemand?.demandType!=='在建项目'?*/}
                        {/*<Form.Item style={{ marginBottom: '5px' }} name='carLicense' label='作业车辆'>*/}
                        {/*    <div>{item?.cleanCar?.carLicense}</div>*/}
                        {/*</Form.Item>:''}*/}
                        {/*    {item?.cleanDemand?.demandType!=='在建项目'?*/}
                        {/*<Form.Item style={{ marginBottom: '5px' }} name='workerPhone' label='作业人员'>*/}
                        {/*    <div>{item?.workerPhone}</div>*/}
                        {/*</Form.Item>:''}*/}

                        {item?.cleanDemand?.demandType!=='在建项目'?
                            <Form.Item style={{ marginBottom: '5px' }} name='workerPhone' label='处理站点'>
                                <div>{item?.garbageSite?.siteName}</div>
                            </Form.Item>:''}

                        <Form.Item style={{ marginBottom: '5px' }} name='workStartTime' label='作业开始时间'>
                            <div>{item?.workStartTime}</div>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: '5px' }} name='workEndTime' label='作业结束时间'>
                            <div>{item?.workEndTime}</div>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '5px' }} name='workEndTime' label='是否退回过'>
                            <div>{item?.hasRectify==1?'是':'否'}</div>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '5px' }} name='workEndTime' label='作业开始定位'>
                            {(item?.startLng && item?.startLat) ? (
                                <div style={{width: '20vw', height: "20vh"}}>
                                    <Map plugins={['ToolBar']} center={{longitude: item?.startLng, latitude: item?.startLat}} zoom={17}>
                                        <Marker position={{longitude: item?.startLng, latitude: item?.startLat}}/>
                                    </Map>
                                </div>
                            ) : (<div>
                                <Tooltip title={'暂无定位'}>
                                    <img src={noneIcon} alt="" width={20} height={20}/>
                                </Tooltip>
                            </div>)}
                        </Form.Item>
                        {item?.cleanDemand?.demandType=='在建项目'?
                            <Form.Item style={{marginBottom: '5px'}} name='receiveAddress' label='接收方地址'>
                                <div>
                                    {item?.cleanDemand?.receiveAddress}
                                </div>
                            </Form.Item> : ''}
                        {item?.cleanDemand?.demandType=='在建项目'?
                            <Form.Item style={{ marginBottom: '5px' }} name='workerPhone' label='接收方定位'>
                                {(item?.cleanDemand?.handleLat && item?.cleanDemand?.handleLng) ? (
                                    <div style={{width: '20vw', height: "20vh"}}>
                                        <Map plugins={['ToolBar']} center={{longitude: item?.cleanDemand?.handleLng, latitude: item?.cleanDemand?.handleLat}} zoom={17}>
                                            <Marker position={{longitude: item?.cleanDemand?.handleLng, latitude: item?.cleanDemand?.handleLat}}/>
                                        </Map>
                                    </div>
                                ) : (<div>
                                    <Tooltip title={'暂无定位'}>
                                        <img src={noneIcon} alt="" width={20} height={20}/>
                                    </Tooltip>
                                </div>)} </Form.Item>:''}

                        <Form.Item style={{marginBottom: '5px'}} name='workEndAddress' label='作业结束地址'>
                            <div>{item?.endAddress}</div>
                        </Form.Item>
                        <Form.Item style={{marginBottom: '5px'}} name='EndLoc' label='作业结束定位'>
                            {(item?.endLng && item?.endLat) ? (
                                <div style={{width: '20vw', height: "20vh"}}>
                                <Map plugins={['ToolBar']}
                                         center={{longitude: item?.endLng, latitude: item?.endLat}} zoom={17}>
                                        <Marker position={{longitude: item?.endLng, latitude: item?.endLat}}/>
                                    </Map>
                                </div>
                            ) : (<div>
                                <Tooltip title={'暂无定位'}>
                                    <img src={noneIcon} alt="" width={20} height={20}/>
                                </Tooltip>
                            </div>)}
                        </Form.Item>
                        {item?.hasRectify==1?
                        <Form.Item style={{ marginBottom: '5px' }} name='workEndTime' label='整改后地址'>
                            <div>{item?.rectifyAddress}</div>
                        </Form.Item>:''}
                        {item?.hasRectify==1?(
                            <Form.Item style={{ marginBottom: '5px' }} name='workEndTime' label='整改后定位'>
                            <div style={{width: '20vw', height: "20vh"}}>
                                <Map plugins={['ToolBar']}
                                     center={{longitude: item?.rectifyLng, latitude: item?.rectifyLat}} zoom={17}>
                                    <Marker position={{longitude: item?.rectifyLng, latitude: item?.rectifyLat}}/>
                                </Map>
                            </div>
                            </Form.Item>
                        ) :''}
                        {item?.cleanWorkPictures?
                            <Form.Item style={{ marginBottom: '5px' }} name='notPassReason' label='作业结束照片'>
                              <Image style={{ marginBottom: '5px' ,height:'200px'}}  src={item.cleanWorkPictures.find((item:any) => item.pictureType === 2)?.url} />
                            </Form.Item>
                            :''}
                        {item?.hasRectify==1?
                            <Form.Item style={{ marginBottom: '5px' }} name='notPassReason' label='整改后作业结束照片'>
                                <Image style={{ marginBottom: '5px' ,height:'200px'}}  src={item.cleanWorkPictures.find((item:any) => item.pictureType === 3)?.url} />
                            </Form.Item>
                         :''}
                        {item?.resultReason?
                            <Form.Item style={{ marginBottom: '5px' }} name='notPassReason' label='驳回原因'>
                                <div>{item?.resultReason}</div>
                            </Form.Item>
                        :''}
                        {/*{item.cleanWorkPictures?*/}
                        {/*        <Form.Item>*/}
                        {/*            <Image src={item.cleanWorkPictures[0].pictureUrl} style={{ width: '200px', height: '100px' }} />*/}
                        {/*        </Form.Item>:""}*/}

                        {item?.cleanDemand?.demandType!=='在建项目'?
                            <Form.Item style={{ marginBottom: '5px' }} name='carId' label='作业车辆'>
                                <Select options={cars}>
                                </Select>
                            </Form.Item>:''}
                        {item?.cleanDemand?.demandType!=='在建项目'?
                            <Form.Item style={{ marginBottom: '5px' }} name='workerPhone' label='作业人员手机'>
                                <Input  placeholder="作业人员手机"/>
                            </Form.Item>:''}
                        {item?.cleanDemand?.demandType!=='在建项目'?
                            <Form.Item style={{ marginBottom: '5px' }} name='roadPlan' label='路线'>
                                <Input  placeholder="路线"/>
                            </Form.Item>:''}
                        {item?.cleanDemand?.demandType!=='在建项目'?
                            <Form.Item style={{ marginBottom: '5px' }} name='transportationCost' label='运输费用'>
                                <InputNumber  placeholder="运输费用" min={0}/>
                            </Form.Item>:''}
                        {item?.cleanDemand?.demandType!=='在建项目'?
                            <Form.Item style={{ marginBottom: '5px' }} name='movingCost' label='搬运费用'>
                                <InputNumber  placeholder="搬运费用" min={0}/>
                            </Form.Item>:''}
                    </Form>
                </Modal>


                <LocationModal  open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={hydrantLat} lng={hydrantLng}/>

            </div>
        );
    }
;

export default Work;